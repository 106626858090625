import "./Story.scss";

import Story1 from "../../images/story-1.png";
import Story2 from "../../images/story-2.png";
import Story3 from "../../images/story-3.png";

export default function Story() {
	return (
		<div className='story'>
			<div className='story-anker' id='about' />
			<div className='container'>
				<h1 className='story-title'>Story</h1>
				<p className='story-description'>
					Enter a world of magic and wonder, where the strange power-source
					known as the Mycelium lies hidden beneath the surface of the earth,
					and the lofty Tollan Peak stretches high above the clouds. Here, three
					city-states vie for dominance, each with their own ways of doing
					magic, and their own views on what the future of this world should be.
				</p>

				<div className='story-box story-1'>
					<img alt='story' src={Story1} className='story-1-img' />
					<p className='story-box-title'>
						A City of
						<br />
						Innovation
						<br />
						and Progress
					</p>
				</div>
				<div className='story-box story-2'>
					<img alt='story' src={Story2} className='story-2-img' />
					<p className='story-box-title'>
						A City of <br />
						Free Spirit
						<br /> and Resilience
					</p>
				</div>
				<div className='story-box story-3'>
					<img alt='story' src={Story3} className='story-3-img' />
					<p className='story-box-title'>
						A City
						<br /> of Power
						<br /> and Trade
					</p>
				</div>
			</div>
		</div>
	);
}

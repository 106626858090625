import React, { useState } from 'react';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Endless from './components/endless/Endless';
import Features from './components/features/Features';
import Screenshots from './components/screenshots/Screenshots';
import Story from './components/story/Story';
import Race from './components/race/Race';
import News from './components/news/News';
import Demo from './components/demo/Demo';
import Greetings from './components/greetings/Greetings';

import PopupRegisterNow from './components/popupRegisterNow';
import PopupSubscribing from './components/popupSubscribing';

function App() {
	const [isShowPopup, setShowPopup] = useState(false);

	const onTogglePopup = () => {
		setShowPopup(!isShowPopup);
	};

	return (
		<div className='App'>
			<Header onTogglePopup={onTogglePopup} />
			<Greetings onTogglePopup={onTogglePopup} />
			<Endless />
			<News />
			<Race />
			<Story />
			<Demo onTogglePopup={onTogglePopup} />
			<Footer />

			<PopupRegisterNow
				isShow={isShowPopup}
				onTogglePopup={onTogglePopup}
			/>

			<PopupSubscribing isShow={true} />
		</div>
	);
}

export default App;

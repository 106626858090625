import React from "react";

import "./button-custom.scss";

const Button = ({ title, onClick }) => {
	return (
		<button className={`button-custom ${title}`} onClick={onClick}>
			<span>{title.replace(/-/g, " ")}</span>
		</button>
	);
};

export default Button;

import React, { useEffect, useState } from "react";

import PlayBtn from "../../play-btn/PlayBtn";

import ButtonCustom from "../../UI/buttonCustom";

import "./item-game.scss";

const ItemGame = ({ game }) => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 576);
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return (
		<li
			className={`item-game ${game.position}`}
			style={game.layout ? { backgroundImage: `url(${game.layout})` } : {}}
		>
			<div className='game-info'>
				<p className='game-info-logo'>
					<img src={game.logo} alt='Tollan worlds' />
				</p>
				<p className='game-info-description'>{game.description}</p>

				{game.button === "play-demo" && isMobile ? (
					<ButtonCustom title='play-on-pc' />
				) : game.button === "play-demo" ? (
					<PlayBtn />
				) : (
					<ButtonCustom title={game.button} />
				)}
			</div>

			<div className='game-pic'>
				{game.pic.type === "video" && (
					<iframe
						width='100%'
						height='100%'
						className='endless-video'
						src={game.pic.url}
						title='YouTube video player'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						allowFullScreen
						autoPlay
					></iframe>
				)}

				{game.pic.type === "img" && <img src={game.pic.src} alt='' />}
			</div>
		</li>
	);
};

export default ItemGame;

import React from "react";

import "./link-custom.scss";

const LinkCustom = ({ title, path }) => {
	return (
		<a
			className={`link-custom ${title}`}
			href={path}
			target='_blank'
			rel='noopener noreferrer'
		>
			<span>{title.replace(/-/g, " ")}</span>
		</a>
	);
};

export default LinkCustom;

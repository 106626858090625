import { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import './PlayBtn.scss';

import LinkCustom from '../UI/linkCustom';
import ButtonCustom from '../UI/buttonCustom';

export default function PlayBtn({ mode = 'button' }) {
	const [isPopupActive, setIsPopupActive] = useState(false);

	useEffect(() => {
		if (isPopupActive) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [isPopupActive]);

	const popupBtn = () => {
		return (
			<div className={`play-btn-wrap ${isPopupActive && 'active'}`}>
				<OutsideClickHandler onOutsideClick={() => setIsPopupActive(false)}>
					<div className='play-btn-block'>
						<div className='play-btn-block-figure-borders' />
						<h1 className='play-btn-block-title'>Select Platform</h1>

						<LinkCustom
							title='play-in-browser'
							path='https://play.tollan.io/'
						/>
						<p className='play-btn-block-or'>Or</p>

						<LinkCustom
							title='play-on-elixir'
							path='https://launcher.elixir.app/browse/tollan-worlds'
						/>
					</div>
				</OutsideClickHandler>
			</div>
		);
	};

	if (mode === 'flag') {
		return (
			<div className='header-demo'>
				<p
					className='header-demo-text'
					onClick={() => setIsPopupActive(true)}
				>
					Play Demo
				</p>
				{popupBtn()}
			</div>
		);
	}

	return (
		<div className='play-btn'>
			<ButtonCustom
				title='play-demo'
				onClick={() => setIsPopupActive(true)}
			/>

			{popupBtn()}
		</div>
	);
}

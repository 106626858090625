import "./Header.scss";
import logo from "../../images/logo-tollan-universe-white.webp";
import { useState, useEffect } from "react";

import ButtonBookmark from "../UI/buttonBookmark";
import ButtonCustom from "../UI/buttonCustom";

export default function Header({ onTogglePopup }) {
	const [isMobileOpen, setIsMobileOpen] = useState(false);

	useEffect(() => {
		if (isMobileOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
		}
	}, [isMobileOpen]);

	return (
		<>
			<header className={`header ${isMobileOpen ? "mobile-open" : ""}`}>
				<div className='container'>
					<img
						alt='logo'
						name='logo'
						src={logo}
						width='168px'
						className='header-logo'
					/>

					<nav className='header-nav'>
						<ul>
							<li>
								<a href='#products'>Products</a>
							</li>
							<li>
								<a href='#news'>News</a>
							</li>
							<li>
								<a href='#world'>World</a>
							</li>
							<li>
								<a href='#links'>Links</a>
							</li>
						</ul>
					</nav>

					<div className='header-nav-mobile'>
						<img alt='logo' src={logo} className='header-nav-mobile-logo' />

						<ButtonCustom title='register-now' onClick={onTogglePopup} />

						<p className='header-nav-mobile-title'>Links</p>
						<div className='header-nav-mobile-icons'>
							<a
								href='https://x.com/TollanUniverse'
								className='header-nav-mobile-icon header-nav-mobile-icon-1'
							>
								#
							</a>
							<a
								href='https://discord.gg/tollan-universe'
								className='header-nav-mobile-icon header-nav-mobile-icon-2'
							>
								#
							</a>
							<a
								href='https://medium.com/@tollan'
								className='header-nav-mobile-icon header-nav-mobile-icon-3'
							>
								#
							</a>
							<a
								href='https://www.youtube.com/@tollanuniverse'
								className='header-nav-mobile-icon header-nav-mobile-icon-4'
							>
								#
							</a>
						</div>
					</div>

					<ButtonBookmark onClick={onTogglePopup} />

					<button
						className='header-nav-mobile-btn'
						onClick={() => setIsMobileOpen(!isMobileOpen)}
					/>
				</div>
			</header>
		</>
	);
}

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import './Race.scss';
import { useEffect, useState } from 'react';

export default function Race() {
	const [isMobile, setIsMobile] = useState(false);

	//choose the screen size
	const handleResize = () => {
		if (window.innerWidth <= 960) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	useEffect(() => {
		handleResize();
	}, []);

	// create an event listener
	useEffect(() => {
		window.addEventListener('resize', handleResize);
	});

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 6,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1600 },
			items: 6,
		},
		tablet: {
			breakpoint: { max: 1600, min: 1100 },
			items: 6,
		},
		tablet1: {
			breakpoint: { max: 1100, min: 960 },
			items: 6,
		},
		mobile: {
			breakpoint: { max: 960, min: 740 },
			items: 3,
		},
		mobile1: {
			breakpoint: { max: 740, min: 0 },
			items: 2,
		},
	};

	return (
		<div className='race'>
			<div
				className='story-anker'
				id='world'
			/>
			<div className='container'>
				<h1 className='race-title'>THE RACES OF TOLLAN</h1>
				<div className='race-desctop'>
					<div className='race-slide race-slide-1'>
						<div className='race-slide-img' />
						<p className='race-slide-title'>Human</p>
					</div>
					<div className='race-slide race-slide-2'>
						<div className='race-slide-img' />
						<p className='race-slide-title'>Felidari</p>
					</div>
					<div className='race-slide race-slide-3'>
						<div className='race-slide-img' />
						<p className='race-slide-title'>Arborean</p>
					</div>
					<div className='race-slide race-slide-4'>
						<div className='race-slide-img' />
						<p className='race-slide-title'>Myconid</p>
					</div>
					<div className='race-slide race-slide-5'>
						<div className='race-slide-img' />
						<p className='race-slide-title'>Golem</p>
					</div>
					<div className='race-slide race-slide-6'>
						<div className='race-slide-img' />
						<p className='race-slide-title'>Luminari</p>
					</div>
				</div>
				<div className='race-mobile'>
					<Carousel
						// additionalTransfrom={0}
						responsive={responsive}
						autoPlay={isMobile} // isMobile
						autoPlaySpeed={3000}
						customTransition='all 3s linear'
						infinite={true}
						draggable={false}
						swipeable={false}
						pauseOnHover={false}
						// transitionDuration={3000}
						arrows={false}
					>
						<div className='race-slide race-slide-1'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Human</p>
						</div>
						<div className='race-slide race-slide-2'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Felidari</p>
						</div>
						<div className='race-slide race-slide-3'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Arborean</p>
						</div>
						<div className='race-slide race-slide-4'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Myconid</p>
						</div>
						<div className='race-slide race-slide-5'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Golem</p>
						</div>
						<div className='race-slide race-slide-6'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Luminari</p>
						</div>
						<div className='race-slide race-slide-1'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Human</p>
						</div>
						<div className='race-slide race-slide-2'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Felidari</p>
						</div>
						<div className='race-slide race-slide-3'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Arborean</p>
						</div>
						<div className='race-slide race-slide-4'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Myconid</p>
						</div>
						<div className='race-slide race-slide-5'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Golem</p>
						</div>
						<div className='race-slide race-slide-6'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Luminari</p>
						</div>
						<div className='race-slide race-slide-1'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Human</p>
						</div>
						<div className='race-slide race-slide-2'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Felidari</p>
						</div>
						<div className='race-slide race-slide-3'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Arborean</p>
						</div>
						<div className='race-slide race-slide-4'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Myconid</p>
						</div>
						<div className='race-slide race-slide-5'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Golem</p>
						</div>
						<div className='race-slide race-slide-6'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Luminari</p>
						</div>
						<div className='race-slide race-slide-1'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Human</p>
						</div>
						<div className='race-slide race-slide-2'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Felidari</p>
						</div>
						<div className='race-slide race-slide-3'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Arborean</p>
						</div>
						<div className='race-slide race-slide-4'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Myconid</p>
						</div>
						<div className='race-slide race-slide-5'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Golem</p>
						</div>
						<div className='race-slide race-slide-6'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Luminari</p>
						</div>
						<div className='race-slide race-slide-1'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Human</p>
						</div>
						<div className='race-slide race-slide-2'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Felidari</p>
						</div>
						<div className='race-slide race-slide-3'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Arborean</p>
						</div>
						<div className='race-slide race-slide-4'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Myconid</p>
						</div>
						<div className='race-slide race-slide-5'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Golem</p>
						</div>
						<div className='race-slide race-slide-6'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Luminari</p>
						</div>
						<div className='race-slide race-slide-1'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Human</p>
						</div>
						<div className='race-slide race-slide-2'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Felidari</p>
						</div>
						<div className='race-slide race-slide-3'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Arborean</p>
						</div>
						<div className='race-slide race-slide-4'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Myconid</p>
						</div>
						<div className='race-slide race-slide-5'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Golem</p>
						</div>
						<div className='race-slide race-slide-6'>
							<div className='race-slide-img' />
							<p className='race-slide-title'>Luminari</p>
						</div>
					</Carousel>
				</div>
			</div>
			<div className='race-border' />
		</div>
	);
}

import { useState, useEffect } from "react";

import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";

import LinkCustom from "../UI/linkCustom";

import "./News.scss";

export default function News() {
	const [mobileSize, setMobileSize] = useState(1.7);

	const checkSize = () => {
		if (window.innerWidth >= 540) {
			setMobileSize(1.7);
		} else {
			setMobileSize(1.3);
		}
	};

	useEffect(() => {
		checkSize();
	}, []);

	//choose the screen size
	const handleResize = () => {
		checkSize();
	};

	// create an event listener
	useEffect(() => {
		window.addEventListener("resize", handleResize);
	});

	const [sliderRef] = useKeenSlider({
		loop: true,
		mode: "free",
		slidesPerView: 1,
		slides: { origin: "center", perView: mobileSize, spacing: 5 },
		range: {
			min: -5,
			max: 5,
		},
	});

	return (
		<div className='news'>
			<div className='news-anker' id='news' />
			<div className='container'>
				<h1 className='news-title'>News</h1>
				<div className='news-wrap'>
					<div className='news-wrap-desctop'>
						<div
							className='news-item news-item-1'
							onClick={() =>
								window.open(
									"https://medium.com/@tollan/tollan-universe-partners-with-xai-games-via-a-publishing-deal-62eeb5dddf53",
									"_blank"
								)
							}
						>
							<div className='news-item-img' />
							<p className='news-item-date'>nov 18, 2024</p>
							<p className='news-item-title'>
							Tollan Universe partners with Xai Games
							</p>
						</div>
						<div
							className='news-item news-item-2'
							onClick={() =>
								window.open(
									"https://medium.com/@tollan/development-update-february-2024-070ddb68eb72",
									"_blank"
								)
							}
						>
							<div className='news-item-img' />
							<p className='news-item-date'>feb 23, 2024</p>
							<p className='news-item-title'>DEVELOPMENT UPDATE FEBRUARY 2024</p>
						</div>
						<div
							className='news-item news-item-3'
							onClick={() =>
								window.open(
									"https://medium.com/@tollan/tollan-worlds-on-otk-games-expo-6c559cc50153",
									"_blank"
								)
							}
						>
							<div className='news-item-img' />
							<p className='news-item-date'>jun 26, 2023</p>
							<p className='news-item-title'>TOLLAN WORLDS ON OTK GAMES EXPO</p>
						</div>
						<div
							className='news-item news-item-4'
							onClick={() =>
								window.open(
									"https://medium.com/@tollan/the-ritual-of-fates-genesis-avatars-launch-on-gamestop-390484f0bbc1",
									"_blank"
								)
							}
						>
							<div className='news-item-img' />
							<p className='news-item-date'>jun 1, 2023</p>
							<p className='news-item-title'>The Ritual of Fates + Genesis Avatars Launch on GameStop</p>
						</div>
					</div>
					{/* <div ref={sliderRef} className='keen-slider'>
						<div className='keen-slider__slide number-slide1'>
							<div className='news-item news-item-1'>
								<div className='news-item-img' />
								<p className='news-item-date'>jun 27, 2023</p>
								<p className='news-item-title'>
									The Ritual of Fates + Genesis Avatars Launch on GameStop
								</p>
							</div>
						</div>
						<div className='keen-slider__slide number-slide2'>
							<div className='news-item news-item-2'>
								<div className='news-item-img' />
								<p className='news-item-date'>jun 27, 2023</p>
								<p className='news-item-title'>
									The Ritual of Fates + Genesis Avatars Launch on GameStop
								</p>
							</div>
						</div>
						<div className='keen-slider__slide number-slide3'>
							<div className='news-item news-item-3'>
								<div className='news-item-img' />
								<p className='news-item-date'>jun 27, 2023</p>
								<p className='news-item-title'>
									The Ritual of Fates + Genesis Avatars Launch on GameStop
								</p>
							</div>
						</div>
						<div className='keen-slider__slide number-slide4'>
							<div className='news-item news-item-4'>
								<div className='news-item-img' />
								<p className='news-item-date'>jun 27, 2023</p>
								<p className='news-item-title'>
									The Ritual of Fates + Genesis Avatars Launch on GameStop
								</p>
							</div>
						</div>
					</div> */}
				</div>

				<LinkCustom title='view-all' path='https://medium.com/@tollan' />
			</div>
		</div>
	);
}

import React from 'react';

import './button-bookmark.scss';

const Bookmark = ({ onClick }) => {
	return (
		<button
			className='button-bookmark'
			onClick={onClick}
		>
			REGISTER NOW
		</button>
	);
};

export default Bookmark;
